<template>
  <div>
    <div class="row">
      <div class="col-md-7">
        <v-card >
          <v-card-title>
            {{ $t("database.title") }}
          </v-card-title>
          <v-card elevation="0" max-height="80vh" class="overflow-auto">
            <div class="d-flex justify-end">
              <v-btn class="mr-2" @click="showMakeDirectoryDialog = true">
                {{ $t("database.make_directory") }}
              </v-btn>
              <v-btn class="mr-2" @click="uploadFilesDialog = true">
                {{ $t("database.upload_files") }}
              </v-btn>
            </div>
            <div >
              <file-browser
              :files="files"
              v-model="chosenFiles"
              root="01-Back-up"
              ></file-browser>
            </div>
          </v-card>
          <div class="text-right pt-2 pb-2 pr-2">
            <v-btn color="green darken-1" dark @click="pushItemsToCart"> {{ $t("database.add") }} </v-btn>
          </div>
        </v-card>
      </div>
      <div class="col-md-5">
        <v-card max-height="90vh">
          <v-card-title>
            {{ $t("database.your_selection") }}
          </v-card-title>
          <div class="text-center">
            <v-btn
              class="mt-3"
              color="primary"
              path="selected"
              large
              dark
              to="/selected"
              v-if="$store.state.selected.length !== 0"
              >{{ $t("database.continue") }}
              <v-icon dark right> mdi-arrow-right </v-icon>
            </v-btn>
            <br />
            <v-btn
              class="mt-3"
              color="red"
              x-small
              dark
              v-if="$store.state.selected.length !== 0"
              @click="deleteAllFromSelection"
              >{{ $t("database.delete_all_button") }}
              <v-icon dark right> mdi-delete </v-icon>
            </v-btn>
            
            <p v-if="$store.state.selected.length === 0" style="color: grey">
              {{ $t("database.select_files") }}
            </p>
          </div>
          <v-card-title v-if="$store.state.selected.length !== 0">
            <div class="pl-2">
              <li
                v-for="item in $store.state.selected"
                :key="item.name"
                style="font-size: 12px"
              >
                {{ item.name }}

                <v-icon left @click="deleteSelectedItem(item)" color="red">
                  mdi-delete
                </v-icon>
              </li>
            </div>
          </v-card-title>
        </v-card>
      </div>

      <v-dialog v-model="showDeleteDialog" max-width="500px" @keydown.enter="deleteSelectedItemConfirm">
        <v-card>
          <v-card-title class="text-h5"
            >{{ $t("database.delete") }}</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showDeleteDialog = false;"
              >{{ $t("database.cancel") }}</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteSelectedItemConfirm"
              >{{ $t("database.ok") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showDeleteAllDialog" max-width="500px" @keydown.enter="deleteAllSelectedItemConfirm">
        <v-card>
          <v-card-title class="text-h5"
            >{{ $t("database.delete_all") }}</v-card-title
          >
          <v-card-text>
            {{ $t("database.delete_all_explanation") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showDeleteAllDialog = false;"
              >{{ $t("database.cancel") }}</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteAllSelectedItemConfirm"
              >{{ $t("database.ok") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showServerErrorDialog" persistent max-width="560px">
        <v-card>
          <v-card-title class="text-h5">{{
            $t("database.server_error")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showServerErrorDialog = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="uploadedSuccessfully" persistent max-width="550">
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("database.files_successfully_uploaded") }}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="uploadedSuccessfully = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-dialog v-model="showMakeDirectoryDialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >{{ $t("database.add_directory") }}
          </v-card-title>
          <v-text-field
            style="margin: 0 10px"
            clearable
            outlined
            :placeholder="$t('database.dir_name')"
            v-model="newDirectoryName"
          ></v-text-field>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="showMakeDirectoryDialog = false; newDirectoryName = '';"
              >{{ $t("database.cancel") }}</v-btn
            >
            <v-btn color="blue darken-1" text @click="makeNewDirectory()"
              >{{ $t("database.save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="uploadFilesDialog" persistent max-width="550">
        <v-card class="px-5">
          <v-card-title>
            {{ $t("database.upload_files") }}
          </v-card-title>
          <template>
            <VueFileAgent 
              v-model="fileRecords"
              :multiple="true"
              :deletable="true"
              :meta="true"
              :accept="'image/png, image/jpeg, image/jpg, video/mp4'"
              :maxSize="'10GB'"
              :helpText="$t('database.click_here_to_upload')"
              :errorText="{
                type: 'Invalid file type. Only images or zip Allowed',
                size: 'Files should not exceed 10GB in size',
              }"
              @select="filesSelected($event)"
              @beforedelete="onBeforeDelete($event)"
              @delete="fileDeleted($event)"
            ></VueFileAgent>
          </template>
        
          <v-card-actions>
            <v-btn color="blue darken-1" dark @click="uploadFilesDialog = false">
              {{ $t("database.cancel") }}
            </v-btn>
            <v-btn
              :disabled="!fileRecordsForUpload.length" 
              color="green darken-1"
              dark
              @click="submitFiles()"
              v-if="fileRecords.length >= 1"
            >
              {{ $t("database.submit") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const path = require("path");
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import FileBrowser from '@/components/fileBrowser.vue';
import { ILLI_API_SERVER_URL } from "../../config";

export default {
  components: {
    FileBrowser
  },
  data() {
    return {
      FileBrowserVueFileAgentStyles: VueFileAgentStyles,
      search: null,
      itemsPerPage: 10,
      isLoading: true,
      itemIndex: -1,
      showDeleteDialog: false,
      showDeleteAllDialog: false,
      content: [],
      files: [],
      fileRecords: [],
      fileRecordsForUpload: [], 
      showServerErrorDialog: false,
      showMakeDirectoryDialog: false,
      uploadFilesDialog: false,
      loadingBarDialog: false,
      uploadedSuccessfully: false,
      chosenFiles: {},
      newDirectoryName: "",
    };
  },

  mounted() {
    if(this.$route.params.new) {
      this.$store.state.selected = [];
    }
    this.loadFiles()
  },

  watch: {
    search(val) {
      if(val) {
        this.content = [{}]
        setTimeout(() => {
          this.content = this.files.map(file => { 
            return {
              name: file.Name,
              id: file.Path,
              path: file.Path,
              isDir: file.IsDir,
            }
          })
        }, 1)
      } else {
        this.setContent()
      }
    }
  },
  methods: {
    uploadFiles: function () {
        // Using the default uploader. You may use another uploader instead.
        this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload);
        this.fileRecordsForUpload = [];
      },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
      // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm('Are you sure you want to delete?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },

    updatePagination(event) {
      this.itemsPerPage = event.itemsPerPage
    },


    /////
    loadFiles() {
      this.isLoading = true;

      axios
        .get(`${ILLI_API_SERVER_URL}/files/01-Back-up-content/`)
        .then((response) => {
          this.files = response.data.list
          this.setContent()
        });
    },

    setContent() {
      this.content = this.files.filter(file => {
        return this.solveDirName(file.Path) === this.chosenFiles.path.join('/')
      }).map(file => { 
        return {
          name: file.Name,
          id: file.Path,
          path: file.Path,
          isDir: file.IsDir,
        }
      })
      this.isLoading = false;
    },

    pushItemsToCart() {
      this.isLoading = true;
      this.chosenFiles.chosenFiles.forEach(item => {
        const selected = this.$store.state.selected.map(selected => selected.path)
        if (!selected.includes(item.Path)) {
          this.$store.state.selected.push({
            name: item.Name,
            path: item.Path,
            isDir: item.IsDir,
          });
        }
      })
      this.chosenFiles.chosenFiles = [];
      this.chosenFiles.path = []
      this.setContent()
    },

    deleteSelectedItem(item) {
      this.showDeleteDialog = true;
      this.itemIndex = this.$store.state.selected.indexOf(item);
    },
    deleteSelectedItemConfirm() {
      this.$store.state.selected.splice(this.itemIndex, 1);
      this.showDeleteDialog = false;
    },
    deleteAllFromSelection() {
      this.showDeleteAllDialog = true;
    },

    deleteAllSelectedItemConfirm() {
      this.$store.state.selected = [];
      this.showDeleteAllDialog = false;
    },
    solveDirName(filePath) {
      const dirName = path.dirname(filePath)
      if (dirName === '.') return ""
      else return dirName
    },

    submitFiles() {
      this.loadingBarDialog = true;
      let formData = new FormData();
      this.fileRecordsForUpload.forEach(file => {
        console.log(file)
        formData.append("files", file.file);
      });
      formData.append("path", this.chosenFiles.path.join("/"));

      axios.post(`${ILLI_API_SERVER_URL}/database/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          this.loadFiles()
          this.fileRecordsForUpload = [];
          this.uploadFilesDialog = false;
          this.loadingBarDialog = false;
          this.uploadedSuccessfully = true;
        }
      })
      .catch(() => {
        this.loadingBarDialog = false;
        this.showServerErrorDialog = true;
      });
    },
    filterWithDashes(value, search) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLowerCase().indexOf(search.toLowerCase().replace(/-/g, "_", ).replace(/ /g, "-")) !== -1
    },
    makeNewDirectory() {
      this.loadingBarDialog = true;
      let formData = new FormData();
      formData.append("path", [...this.chosenFiles.path, this.newDirectoryName].join("/"));

      axios.post(`${ILLI_API_SERVER_URL}/directory`,
        formData,
      )
      .then((response) => {
        if (response.status == 200) {
          this.showMakeDirectoryDialog = false;
          this.loadingBarDialog = false;
          this.uploadedSuccessfully = true;
          this.newDirectoryName = '';
          this.files = []
          this.loadFiles()
        }
      })
      .catch(() => {
        this.loadingBarDialog = false;
        this.showServerErrorDialog = true;
      });
    }
  },
};
</script>

<style>
.v-label.theme--light {
  color: black !important;
}
</style>
